<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <the-nerve-table
    :id="'iiotLabelListTable'"
    :columns="columns"
    :params="params"
    :empty-state="$t('labels.list.empty')"
    :is-action-menu-enabled="false"
    :is-row-clickable="canAccess('UI_LABEL:VIEW')"
    class="mr-4"
    store-module="labels"
    @row-clicked="(params) => addNewLabel(params.item)"
    @params-changed="(params) => changedParams(params)"
  >
    <template #additional-actions>
      <div class="mr-6">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              v-if="canAccess('UI_LABEL:GROUP')"
              id="iiotLabelListMergeLabelsButton"
              fab
              small
              dark
              class="mr-1 primary-tttech-background-btn"
              v-on="on"
              @click="merge()"
            >
              <v-icon dark> call_merge </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('labels.merge.mergeLabels') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              v-if="canAccess('UI_LABEL:CREATE')"
              id="iiotLabelListAddNewLabelButton"
              fab
              small
              dark
              class="primary-tttech-background-btn"
              v-on="on"
              @click="addNewLabel()"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('labels.list.addLabel') }}</span>
        </v-tooltip>
      </div>
    </template>
  </the-nerve-table>
</template>

<script>
import TheNerveTable from 'nerve-ui-components/components/TheNerveTable.vue';
import Logger from '@/utils/logger';

export default {
  components: {
    TheNerveTable,
  },
  props: {
    page: {
      type: Number,
    },
    itemsPerPage: {
      type: Number,
    },
    search: {
      type: String,
    },
    sortBy: {
      type: Array,
    },
    sortDesc: {
      type: Array,
    },
  },
  data() {
    return {
      params: {},
    };
  },
  computed: {
    columns() {
      if (this.canAccess('UI_LABEL:DELETE')) {
        return [
          {
            text: this.$t('labels.list.key'),
            value: 'key',
            width: '30%',
          },
          {
            text: this.$t('labels.list.value'),
            value: 'value',
            width: '70%',
          },
          {
            text: this.$t('defaultTable.action'),
            value: '',
            width: '0%',
            sortable: false,
            component: {
              sfc: () => import('@/components/labels/LabelListTableAction.vue'),
              props: {
                iconsAction: ['Delete'],
                fetchAction: () => this.filterBy,
              },
            },
          },
        ];
      }
      return [
        {
          text: this.$t('labels.list.key'),
          value: 'key',
        },
        {
          text: this.$t('labels.list.value'),
          value: 'value',
        },
      ];
    },
  },
  created() {
    this.params = {
      page: this.page || 1,
      itemsPerPage: this.itemsPerPage || 10,
      search: this.search || '',
      sortBy: this.sortBy || [],
      sortDesc: this.sortDesc || [],
    };
  },
  methods: {
    async filterBy() {
      try {
        await this.$store.dispatch('labels/fetch', this.params);
      } catch (e) {
        Logger.error(e);
      }
    },
    changedParams(params) {
      this.params = params;
      this.$router
        .push({
          name: 'Labels',
          query: {
            page: params.page,
            itemsPerPage: params.itemsPerPage,
            search: params.search || undefined,
            sortBy: params.sortBy ? params.sortBy[0] : [],
            sortDesc: params.sortDesc ? params.sortDesc[0] : [],
          },
        })
        .catch(() => {});
    },
    addNewLabel(param) {
      if (param) {
        this.$router.push({ name: 'Add Edit Label', params: { id: param._id } });
        return;
      }
      this.$router.push({ name: 'Add Edit Label', params: { id: 'new' } });
    },
    async merge() {
      await this.$store.dispatch('labels/save_query', this.params);
      this.$router.push({ name: 'Merge' });
    },
  },
};
</script>
